(() => {
  // const baseURL = 'https://google.com?'
  const baseURL = 'https://www.choicehotels.com/texas/dallas/cambria-hotels/txi93/rates?'
  

  const checkin = document.querySelector('#check-in')
  const checkout = document.querySelector('#check-out')
  const rooms = document.querySelector('#rooms')
  const book = document.querySelector('#nav-booking-button')

  initialize()

  function initialize() {
    const today = new Date()
    const tomorrow = getFollowing(today)
    
    setData(checkin, today)
    setData(checkout, tomorrow)
    rooms.value = 1;

    checkin.addEventListener('change', updateCheckout)
    book.addEventListener('click', submit)
  }

  function getFollowing(date) {
    const next = new Date(date)
    next.setDate(next.getDate() + 1)
    return next
  }

  function setData(input, value) {
    input.value = formatDate(value)
    input.min = formatDate(value)
  }

  function formatDate(date) {
    return date.toISOString().split('T')[0]
  }

  function updateCheckout() {
    if ( checkin.value >= checkout.value ) {
      setData(checkout, getFollowing(checkin.value))
    }
  }

  function submit() {
    const checkinStr = `checkInDate=${checkin.value}`
    const checkoutStr = `checkOutDate=${checkout.value}`
    const roomsStr = `rooms=${rooms.value}`

    const query = `${baseURL}${checkinStr}&${checkoutStr}&${roomsStr}`
    window.open( query, "_blank" );
  }
})()