(() => {
  const navtoggle = document.querySelector('#navigation-toggle')
  const navigation = document.querySelector('#site-navigation')

  
  const bartoggle = document.querySelector('#booking-toggle')
  const bar = document.querySelector('#booking-bar')

  navtoggle.addEventListener('click', _ => {
    if ( navtoggle.dataset.toggled === 'false' ) {
      bartoggle.dataset.toggled = 'false'
      bar.dataset.showing = 'false'
      navtoggle.dataset.toggled = 'true'
      navigation.dataset.showing = 'true'
    } else {
      navtoggle.dataset.toggled = 'false'
      navigation.dataset.showing = 'false'
    }
  })

  bartoggle.addEventListener('click', _ => {
    if ( bartoggle.dataset.toggled === 'false' ) {
      navtoggle.dataset.toggled = 'false'
      navigation.dataset.showing = 'false'
      bartoggle.dataset.toggled = 'true'
      bar.dataset.showing = 'true'
    } else {
      bartoggle.dataset.toggled = 'false'
      bar.dataset.showing = 'false'
    }
  })
})()