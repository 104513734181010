(() => {
  if ( window.location.hash ) {
    const hash = window.location.hash
    window.location.hash = ''
    
    window.addEventListener('load', () => handleScroll(hash))
  }
})()

function handleScroll(hash) {
  const headerOffset = 100;
  const el = document.querySelector(hash)
  const elPos = el.getBoundingClientRect().top
  const offsetPos = elPos - headerOffset;

  window.scrollTo({
    top: offsetPos,
    behavior: "smooth"
  });
}