(() => {  
  const bulletinsSection = document.querySelector('#bulletins')

  if ( bulletinsSection ) {
    const bulletins = bulletinsSection.querySelectorAll('.bulletin')
      bulletins.forEach(bulletin => {
        const control = bulletin.querySelector('button.bulletin__control')

        setTimeout(() => {
          bulletin.dataset.expanded = 'true'
        }, 500)

        control.addEventListener('click', e => close(control.dataset.id))

        function close(id) {
          document.querySelector(`#${id}`).dataset.expanded = 'false';
        }
      })
  }
})()